import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import { useTranslation } from "react-i18next";

import "react-phone-number-input/style.css";
import "./style.scss";

export const CustomPhoneInput = (props) => {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  function validatePhoneNumber(value) {
    let error;
    if (!value) {
      error = `${t("PhoneNumberRequired")}`;
    } else {
      error = isPhoneNumber(value);
    }

    return error || true;
  }

  function isPhoneNumber(value) {
    if (!isValidPhoneNumber(value)) return `${t("PhoneNumberInvalid")}`;
  }

  return (
    <div className="wrapper-input">
      <label htmlFor={props.id}>{t(props.label)}</label>
      <div className="input-container">
        <Controller
          as={
            <Input
              id={props.id}
              smartCaret={false}
              className={
                "input-custom " +
                props.class +
                (errors[props.name]?.message ? " error" : "")
              }
            />
          }
          name={props.name}
          control={control}
          defaultValue=""
          rules={{ validate: (value) => validatePhoneNumber(value) }}
        />
        {errors[props.name]?.ref.name && (
          <>
            <div className="error__message-block">
              <p className="error__message error__message--cardholderName">
                {errors[props.name]?.message}
              </p>
            </div>
          </>
        )}
        {props.additionalChild && props.additionalChild}
      </div>
    </div>
  );
};
